import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

import { Row, Col, Table } from "antd";
import * as actions from "../../../redux/actions";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditParkingForm: false,
      currentEditParkingData: false,
    };
  }

  componentDidMount() {
    this.props.adminGetUsersRequest();
  }

  _getTableColumns = () => {
    const columns = [
      {
        title: "Register Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (data) => moment(data).format("DD-MMM-YYYY, h:mm:ss a"),
      },
      {
        title: "Email Verified",
        dataIndex: "isEmailVerified",
        key: "isEmailVerified",
        render: (status) => (status ? "Y" : "N"),
      },
      // {
      //   title: "Mobile Verified",
      //   dataIndex: "isMobileVerified",
      //   key: "isMobileVerified",
      //   render: (status) => (status ? "Y" : "N"),
      // },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        //render: (text) => <a>{text}</a>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "Favourite Products",
        dataIndex: "countUserFavProducts",
        key: "countUserFavProducts",
      },
      {
        title: "Email Favourite Products",
        dataIndex: "email_sent_user_favourites_list",
        key: "email_sent_user_favourites_list",
        render: (data) =>
          data && data !== ""
            ? moment(data).format("DD-MMM-YYYY, h:mm:ss a")
            : "-",
      },
      // {
      //   title: "Location",
      //   dataIndex: "myLocation",
      //   key: "myLocation",
      //   render: (data) => {
      //     let loc = "--";
      //     if (data && data.locationName) {
      //       loc =
      //         data.locationName +
      //         ", " +
      //         data.locationAddress +
      //         ", " +
      //         data.locationCity +
      //         ", " +
      //         data.locationState;
      //     }
      //     return <>{loc}</>;
      //   },
      // },
      // {
      //   title: "Tags",
      //   key: "tags",
      //   dataIndex: "tags",
      //   render: (tags) => (
      //     <>
      //       {tags.map((tag) => {
      //         let color = tag.length > 5 ? "geekblue" : "green";
      //         if (tag === "loser") {
      //           color = "volcano";
      //         }
      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </>
      //   ),
      // },
      // {
      //   title: "Action",
      //   key: "action",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <a>Invite {record.name}</a>
      //       <a>Delete</a>
      //     </Space>
      //   ),
      // },
    ];
    return columns;
  };

  _getTableData = () => {
    let { usersList } = this.props;
    return usersList;
  };

  render() {
    return (
      <Row id="my-parkings">
        <Col xl={24} md={24} xs={24}>
          <h3>Users</h3>
          <Table
            columns={this._getTableColumns()}
            dataSource={this._getTableData()}
            size="small"
            pagination={{ pageSize: 200 }}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: state.admin.usersList.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetUsersRequest: (data) => dispatch(actions.adminGetUsersRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
