import React, { useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Input, Select, InputNumber, Dropdown, Button } from "antd";
import {
  SearchOutlined,
  DownOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  CaretDownFilled,
} from "@ant-design/icons";

import * as actions from "../../redux/actions";
import { showNotification } from "../../services/index.js";
import { Generic } from "../../utils";

const { Search } = Input;
const { Option } = Select;

const { isValidUrl } = Generic;

function SearchBoxNew(props) {
  const searchBoxRef = useRef(null);
  const { websitesToProcess, menu, searchCategory } = props;
  const searchCategories = menu?.searchCategories || [];

  useEffect(() => {
    props.doSearchReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onTextSearch = (searchText) => {
    props.doSearchReset();
    if (searchText.trim() === "") {
      showNotification({
        type: "warning",
        title: "Enter text to search",
      });
    } else if (isValidUrl(searchText)) {
      showNotification({
        type: "warning",
        title: "Enter valid text to search",
      });
    } else {
      // check if any website is seleted or not
      let selectedWebsites = websitesToProcess.filter(
        (m) => m.selectedForProcess === true
      );
      if (selectedWebsites.length > 0) {
        let url = "/price-search/" + searchText.trim();
        props.history.push(url);
      } else {
        showNotification({
          type: "warning",
          title: "Select at least one website",
        });
      }
    }
  };

  let findDefaultSearchCategory = searchCategories.find(
    (c) => c.defaultSearchCategory
  );
  let defaultSelectedSearchCategoryId =
    (findDefaultSearchCategory && findDefaultSearchCategory?.categoryId) || "";

  let localStorageCategorySelected = localStorage.getItem("SEARCH_CATEGORY");
  if (localStorageCategorySelected) {
    let chk = JSON.parse(localStorageCategorySelected);
    if (chk && chk?.categoryId) {
      defaultSelectedSearchCategoryId = chk?.categoryId || "";
    }
  }

  let selectedCategoryName = findDefaultSearchCategory?.categoryName || "";

  let items = searchCategories.map((sc) => {
    const { categoryName, categoryId } = sc;
    let blockClass = "website-block search-category-block";
    let isSelected = false;
    if (categoryId === defaultSelectedSearchCategoryId) {
      isSelected = true;
      selectedCategoryName = categoryName;
    }
    // if (searchCategory && searchCategory.categoryId === categoryId) {
    //   isSelected = true;
    //   selectedCategoryName = categoryName;
    // }
    // console.log("A --- selectedCategoryName -- " + selectedCategoryName);
    // console.log(searchCategory);
    // if (!isSelected) {
    //   blockClass += " block-not-selected";
    // } else {
    //   blockClass += " block-selected";
    // }
    return {
      key: categoryId,
      label: (
        <div
          className={isSelected ? "selected-category-in-dropdown" : ""}
          onClick={() => toggleSearchCategory(sc)}
        >
          <CaretRightOutlined /> {categoryName}
        </div>
      ),
    };
  });

  const toggleSearchCategory = (wp) => {
    searchBoxRef.current.focus();
    props.toggleSearchCategory({
      data: wp,
    });
  };

  return (
    <>
      <Row id="landing-search-box-new">
        {/* <Input.Group compact> */}
        <Col className="" xl={7} md={7} xs={7}>
          <Dropdown
            className="btn-category"
            menu={{
              items,
            }}
            placement="bottom"
            trigger="click"
          >
            <Button style={{}}>
              <CaretDownFilled />
              <span className="category-name">{selectedCategoryName}</span>
            </Button>
          </Dropdown>
          {/* <Select style={{ width: "100%", fontSize: 8 }}>
            {searchCategories.map((sc) => {
              const { categoryName, categoryId } = sc;
              return <Option value={categoryId}>{categoryName}</Option>;
            })}
          </Select> */}
        </Col>
        <Col className="" xl={17} md={17} xs={17}>
          {/* <Input
            className="input-search"
            placeholder="What are you looking for today?"
            style={{ borderRight: 0 }}
          /> */}
          <Search
            ref={searchBoxRef}
            placeholder="What are you looking for today?"
            allowClear
            // enterButton="Search"
            size="large"
            onSearch={_onTextSearch}
            // style={{padding:20}}
            className="input-search"
            autoComplete="on"
          />
        </Col>

        {/* <Col
          className=""
          xl={2}
          md={2}
          xs={2}
          style={{
            border: "1px solid #d9d9d9",
            borderLeft: 0,
            textAlign: "center",
          }}
        >
          <SearchOutlined
            style={{ display: "flex", justifyContent: "center" }}
          />
        </Col> */}
        {/* </Input.Group> */}
        <div className="search-text-hint">
          - Try to enter search text in details like e.g. iphone 13 mini 256 gb,
          jacket black blue, black socks
        </div>
      </Row>
      {/* <Row id="landing-search-box">
       
        <Col className="" xl={24} md={24} xs={24}>
          <Search
            placeholder="What are you looking for today?"
            allowClear
            // enterButton="Search"
            size="large"
            onSearch={_onTextSearch}
            // style={{padding:20}}
            className="landing-search-inputbox"
            autoComplete="on"
          />
          <div className="search-text-hint">
            - Try to enter search text in details like e.g. iphone 13 mini 256
            gb, jacket black blue, black socks
          </div>
        </Col>
      </Row> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
  menu: state.generic.menu.data || {},
  searchCategory: state.websearch.productWebSearch.searchCategory || false,
});

const mapDispatchToProps = (dispatch) => ({
  doSearchReset: (data) => dispatch(actions.doSearchReset(data)),
  toggleSearchCategory: (data) => dispatch(actions.toggleSearchCategory(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBoxNew)
);
