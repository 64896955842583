import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Select, Row, Col, Button, Spin } from "antd";

import * as actions from "../../redux/actions";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class FormEditProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      isMyProfileFetched: false,
      initialValues: {
        name: "",
        mobileNumber: "",
        myLocationId: "",
      },
    };

    this.editProfileFormRef = React.createRef();
  }

  submitForm = (values) => {
    // console.log(values);
    //this.props.newParkingRequest(values);
    this.props.updateMyProfileRequest(values);
  };

  componentDidMount() {
    this.props.getAllLocationsRequest();
    this.props.getMyProfileRequest();
    this.setState({
      editLinkData: this.props.editLinkData,
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.editLinkData !== props.editLinkData) {
      return {
        editLinkData: props.editLinkData,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.myProfile);
    // console.log(this.props.myProfile);
    if (prevProps.myProfile !== this.props.myProfile) {
      const { myProfile } = this.props;
      this.setState({
        isMyProfileFetched: true,
        initialValues: {
          name: myProfile.name || "",
          mobileNumber: myProfile.mobileNumber || "",
          myLocationId: myProfile.myLocationId || "",
        },
      });
      // console.log(this.editProfileFormRef);
      // console.log(this.editProfileFormRef);
      // console.log(this.editProfileFormRef);
      // console.log(this.editProfileFormRef);
      // console.log(this.editProfileFormRef);

      if (this.editProfileFormRef.current) {
        this.editProfileFormRef.current.setFieldsValue({
          name: myProfile.name || "",
          mobileNumber: myProfile.mobileNumber || "",
          myLocationId: myProfile.myLocationId || "",
        });
      }
    }
  }

  render() {
    const { locations, updateMyProfile } = this.props;
    const { isMyProfileFetched, initialValues } = this.state;

    return (
      <Row id="form-add-newparking">
        <Col xl={24} md={24} xs={24}>
          <h3>Edit Profile</h3>
          {!isMyProfileFetched ? (
            <Spin />
          ) : (
            <Form
              ref={this.editProfileFormRef}
              {...formItemLayout}
              // form={form}
              name="editProfile"
              onFinish={this.submitForm}
              initialValues={initialValues}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Enter your full name",
                  },
                ]}
              >
                <Input autoComplete={false} />
              </Form.Item>

              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Enter mobile number",
                  },
                  {
                    message: "Enter valid mobile number",
                    pattern: /^\d{10}$/,
                  },
                ]}
              >
                <Input autoComplete={false} />
              </Form.Item>

              <Form.Item
                name="myLocationId"
                label="My Location"
                rules={[{ required: true, message: "Select Your Location" }]}
              >
                <Select
                  showSearch
                  placeholder="Select Location"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {locations.map((element) => {
                    let optionText =
                      element.locationName +
                      ", " +
                      element.locationAddress +
                      ", " +
                      element.locationCity +
                      ", " +
                      element.locationState;
                    return (
                      <Option key={element._id} value={element._id}>
                        {optionText}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                {updateMyProfile && updateMyProfile.isLoading ? (
                  <Spin />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Update Profile
                  </Button>
                )}
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    );
  }
}

// export default FormAddNewShortShout;

const mapStateToProps = (state) => ({
  updateMyProfile: state.auth.updateMyProfile || false,
  loggedUserData: state.auth.login.data || false,
  locations: state.locations.locations.data || [],
  myProfile: state.auth.myProfile.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  getAllLocationsRequest: (data) =>
    dispatch(actions.getAllLocationsRequest(data)),
  newParkingRequest: (data) => dispatch(actions.newParkingRequest(data)),
  getMyProfileRequest: (data) => dispatch(actions.getMyProfileRequest(data)),
  updateMyProfileRequest: (data) =>
    dispatch(actions.updateMyProfileRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditProfile)
);
