import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Landing from "../components/landing";

import * as actions from "../redux/actions";

function LandingPage(props) {
  useEffect(() => {
    props.getTopPriceDropProductsRequest({ limit: 10 });
    props.getTopPopularProductsRequest({ limit: 10 });
    props.getTopRecentProductsRequest({ limit: 10 });
  }, []);

  let title = "Find online product price - Cheapshops.in";
  if (window.DOMAIN_NAME === "price2click") {
    title = "Find online product price - Price2click.com";
  }

  return (
    <div id="landing-page">
      <Helmet>
        <meta charSet="utf-8" />
        {/* <meta name="description" content={metaDescription} /> */}
        {/* <meta name="title" content={metaTitle} /> */}
        <title>{title}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HeaderMain />
      <div className="page-content">
        <Landing />
      </div>
      <FooterMain />
    </div>
  );
}

const mapStateToProps = (state) => ({
  topPriceDropProducts: state?.products?.topPriceDropProducts?.data || [],
  topPopularProducts: state?.products?.topPopularProducts?.data || [],
  topRecentProducts: state?.products?.topRecentProducts?.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  getTopPriceDropProductsRequest: (data) =>
    dispatch(actions.getTopPriceDropProducts.request(data)),
  getTopPopularProductsRequest: (data) =>
    dispatch(actions.getTopPopularProducts.request(data)),
  getTopRecentProductsRequest: (data) =>
    dispatch(actions.getTopRecentProducts.request(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
