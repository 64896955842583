import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import {
  fireApi,
  showNotification,
  setLoggedInSession,
  getLoggedUser,
  clearLoggedInSession,
  tempVariableToHold_isEmailVerified,
} from "../../services";

export function* registerRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {};

  const response = yield call(
    fireApi,
    "POST",
    `/auth/register`,
    action.payload,
    header
  );
  const { data } = response;
  if (data.error) {
    yield put(actions.registerError(response.data));
    showNotification({
      type: "error",
      title: "Registration Failed!!",
      message: data.message,
    });
  } else {
    yield put(actions.registerSuccess(response.data));
    showNotification({
      type: "success",
      title: data.message,
    });
  }

  yield put(actions.showProgressBar(false));
}

export function* loginRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const response = yield call(
    fireApi,
    "POST",
    `/auth/login`,
    action.payload,
    header
  );

  const { data } = response;
  if (data.error) {
    yield put(actions.loginError(response.data));
    showNotification({
      type: "error",
      title: "Login Failed!!",
      message: data.message,
    });
  } else {
    setLoggedInSession(response.data.data.token);
    let userInfo = getLoggedUser();
    yield put(actions.loginSuccess(userInfo));
    // is success call user data which will fetch product information and will be used to add/remove fav button
    yield put(actions.getMyFullDataRequest());
  }
  // try {
  //   const response = yield call(
  //     fireApi,
  //     "POST",
  //     `/auth/login`,
  //     action.payload,
  //     header
  //   );
  //   if (response) {
  //     setLoggedInSession(response.data.data.token);
  //     let userInfo = getLoggedUser();
  //     yield put(actions.loginSuccess(userInfo));
  //     // toast.success(response.data.message);
  //   }
  // } catch (e) {
  //   yield put(actions.loginError(e.response.data));
  //   showNotification({
  //     type: "error",
  //     title: "Login Failed!!",
  //     message: e.response.data.message,
  //   });
  // }
  yield put(actions.showProgressBar(false));
}

export function* isLoggedInUser(action) {
  let userInfo = getLoggedUser();
  if (userInfo && userInfo.token) {
    yield put(actions.loginSuccess(userInfo));
  }
  // else {
  //   yield put(actions.logoutSuccess());
  // }
}

export function* logoutRequest(action) {
  yield put(actions.showProgressBar(true));
  //   const header = {};
  //   try {
  //     const response = yield call(
  //       fireApi,
  //       "POST",
  //       `/auth/logout`,
  //       action.payload,
  //       header
  //     );
  clearLoggedInSession();
  //     yield put(actions.logoutRequestSuccess(response));
  //   } catch (e) {
  //     clearLoggedInSession();
  //     yield put(actions.logoutRequestError());
  //   }
  yield put(actions.showProgressBar(false));
}

/* update password request*/
export function* updatePasswordRequest(action) {
  let payload = {
    oldPassword: action.payload.oldPassword || "",
    newPassword: action.payload.newPassword || "",
    confirmPassword: action.payload.confirmPassword || "",
  };
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const response = yield call(
    fireApi,
    "POST",
    `/auth/updatePassword`,
    payload,
    header
  );

  const { data } = response;
  if (data.error) {
    yield put(actions.updatePasswordError(data));
    showNotification({
      type: "error",
      title: "Update password Failed!!",
      message: data.message,
    });
  } else {
    yield put(actions.updatePasswordSuccess(data));
    showNotification({
      type: "success",
      title: "Update password Success!!",
      message: data.message,
    });
  }
}

export function* resetPasswordRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const response = yield call(
    fireApi,
    "POST",
    `/auth/resetPassword`,
    action.payload,
    header
  );
  const { data } = response;
  if (data.error) {
    yield put(actions.resetPasswordError(data));
    showNotification({
      type: "error",
      title: "Reset Password Failed!!",
      message: data.message,
    });
  } else {
    yield put(actions.resetPasswordSuccess(data));
    showNotification({
      type: "success",
      title: "Reset Password Success!!",
      message: data.message,
    });
  }
  yield put(actions.showProgressBar(false));
}

export function* verifyEmailRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  const response = yield call(
    fireApi,
    "POST",
    `/auth/verifyEmail`,
    action.payload,
    header
  );

  const { data } = response;
  if (data.error) {
    yield put(actions.verifyEmailError(data));
    showNotification({
      type: "error",
      title: "Email Verified Failed!!",
      message: data.message,
    });
  } else {
    tempVariableToHold_isEmailVerified(true);
    yield put(actions.verifyEmailSuccess(data));
    showNotification({
      type: "success",
      title: "Email Verified Successfully!!",
      message: data.message,
    });
  }

  yield put(actions.showProgressBar(false));
}

export function* sendEmailVerifyCodeRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const response = yield call(
    fireApi,
    "POST",
    `/auth/sendEmailVerifyCode`,
    action.payload,
    header
  );

  const { data } = response;
  if (data.error) {
    yield put(actions.sendEmailVerifyCodeError(data));
    showNotification({
      type: "error",
      title: "Failed to sent verification code!!",
      message: data.message,
    });
  } else {
    yield put(actions.sendEmailVerifyCodeSuccess(data));
    showNotification({
      type: "success",
      title: "Success!!",
      message: data.message,
    });
  }
  yield put(actions.showProgressBar(false));
}

// get user all data
export function* getMyFullDataRequest(action) {
  let userInfo = getLoggedUser();
  if (userInfo === false) {
  } else {
    yield put(actions.showProgressBar(true));
    const header = {
      // withCredentials: true,
      "Cache-Control": "no-cache",
    };

    const response = yield call(
      fireApi,
      "POST",
      `/auth/getMyFullData`,
      action.payload,
      header
    );

    const { data } = response;
    if (data.error) {
      yield put(actions.getMyFullDataError(data));
    } else {
      yield put(actions.getMyFullDataSuccess(data.data));
    }
    yield put(actions.showProgressBar(false));
  }
}
