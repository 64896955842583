import React from "react";
import { Layout } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  AndroidOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;

class FooterMain extends React.Component {
  render() {
    let instagramLink = "https://www.instagram.com/cheapshops.in";
    let facebookLink = "https://www.facebook.com/cheapshops.in";
    if (window.DOMAIN_NAME === "price2click") {
      instagramLink = "https://www.instagram.com/price2click";
      facebookLink = "https://www.facebook.com/price2click";
    }
    return (
      <Footer
        style={{
          textAlign: "center",
          marginTop: "20px",
          background: "transparent",
          fontWeight: "bold",
          color: "#1f1c4f",
        }}
      >
        {/* <a href="mailto: parkinghunter@outlook.com">Contact Us</a> */}
        {/* &nbsp;&nbsp;&nbsp; &nbsp;
        <a href="https://parkinghunter.in" target="_BLANK">
          parkinghunter.in
        </a> */}
        {/* <br /> */}
        <a
          href="https://play.google.com/store/apps/details?id=com.arun2466.cheapshopsmobile"
          target="_BLANK"
          title="Android App on play store"
        >
          <AndroidOutlined className="footer-social-icons" />
        </a>
        <a href={instagramLink} target="_BLANK" title="Instagram">
          <InstagramOutlined className="footer-social-icons" />
        </a>
        <a href={facebookLink} target="_BLANK" title="Facebook">
          <FacebookOutlined className="footer-social-icons" />
        </a>

        <a href="mailto:contact@price2click.com">
          <MailOutlined className="footer-social-icons" />
        </a>

        {/* <a href="https://linksredirect.com/?cid=5004&source=linkkit&url=https%3A%2F%2Fwww.shopify.com">
          <img
            src={IconShopify}
            style={{ height: "20px", marginTop: 5, position: "absolute" }}
          />
        </a> */}

        {/* <br />
        <a
          href="https://www.mouthshut.com/websites/Cheapshops-in-reviews-926125621"
          target="_BLANK"
          title="Mouthshut"
          style={{ color: "#1f1c4f", textDecoration: "underline" }}
        >
          Rate us on - Mouthshut
        </a> */}
        {/* <br />
        <a
          href="https://www.trustpilot.com/review/cheapshops.in"
          target="_BLANK"
          title="Trustpilot"
          style={{ color: "#1f1c4f", textDecoration: "underline" }}
        >
          Rate us on - Trustpilot
        </a> */}
        {/* <a
          href="https://shortshout.in/parkinghunter"
          target="_BLANK"
          title="shortshout"
          id="footer-shortshout"
        >
          <img src={shortshoutIcon} />
        </a> */}
        <br />

        <a href="/disclaimer" target="_BLANK" style={{ color: "#1f1c4f" }}>
          Disclaimer
        </a>
        <a
          href="/privacy-policy"
          target="_BLANK"
          style={{ color: "#1f1c4f", marginLeft: 5 }}
        >
          Privacy Policy
        </a>
      </Footer>
    );
  }
}

export default FooterMain;
