import React from "react";
import { connect } from "react-redux";

import { Row, Col } from "antd";
import { Button } from "antd";
import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import * as actions from "../redux/actions";

import { GoogleAnalytics } from "../utils/index.js";

import ButtonFavouriteProduct from "../components/product/ButtonFavouriteProduct";

function ProductWebSearch(props) {
  const { data, showPriceHistoryLink, openLinkInNewTab } = props;
  const {
    name,
    price,
    image,
    url,
    website,
    productId,
    viewCount,
    priceDiff,
    coupons,
    currency,
    productPageUrl,
  } = data;
  let webLogo =
    "https://cheapshops.in/api/static/images/websites_logo/" + website + ".png";

  let showAddRemoveFavButton = false;
  if (website && website !== "" && productId && productId !== "") {
    showAddRemoveFavButton = true;
  }

  const trackBuyNowClick = () => {
    GoogleAnalytics.trackEvent({
      category: "BuyNowClick",
      action: website,
      label: price,
      value: url,
    });

    props.buyNowClickRequest({
      website: website,
      productId: productId,
      price: price,
    });
  };

  let showPriceDiff = false;
  let priceDiffTextColor = "#2c531f";
  if (priceDiff) {
    if (priceDiff > 0) {
      priceDiffTextColor = "red";
      showPriceDiff = "Price up " + currency + priceDiff;
    } else {
      showPriceDiff = "Price drop " + currency + priceDiff;
    }
  }

  return (
    <>
      <Row id="product-web-search">
        {isMobile && true === false ? (
          <Col md={24} xl={24} sm={24} xs={24}>
            {showPriceDiff ? (
              <Row>
                <Col
                  xs={24}
                  className="price-drop-blinker"
                  style={{ color: priceDiffTextColor }}
                >
                  {showPriceDiff}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={12}>
                <img src={webLogo} className="websitelogo" alt={website} />
              </Col>
              <Col xs={12} className="price">
                {currency}
                {price}
                <br />
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="image">
                <img src={image} className="image-product" alt={name} />
                <br />
                <span className="view-count-product">
                  {viewCount && viewCount > 0 ? viewCount + " views" : null}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="product-name">
                <a
                  href={url}
                  target="_BLANK"
                  onClick={trackBuyNowClick}
                  style={{ color: "black" }}
                >
                  {name}
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="image">
                <a
                  href={url}
                  // target="_BLANK"
                  onClick={trackBuyNowClick}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="buy-button"
                  >
                    Buy Now
                  </Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="product-name">
                {!productPageUrl || showPriceHistoryLink === false ? null : (
                  <Link
                    target="_blank"
                    to={productPageUrl}
                    style={{ lineHeight: 2.5, fontWeight: "bold" }}
                  >
                    View Price History
                  </Link>
                )}
              </Col>
              <Col xs={12} className="product-name">
                {showAddRemoveFavButton ? (
                  <ButtonFavouriteProduct
                    website={website}
                    productId={productId}
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        ) : (
          <>
            {/* <Col className="website-logo">
              <img src={webLogo} alt={website} />
            </Col> */}
            <Col className="product-image">
              <img src={image} />
              <br />
              <div className="view-count-product">
                {viewCount && viewCount > 0 ? viewCount + " views" : null}
              </div>
            </Col>
            <Col className="product-name">
              {coupons && coupons.length > 0 ? (
                <span className="coupon-code-alert">
                  {coupons.map((coupon) => {
                    const { code, description } = coupon;
                    return (
                      <>
                        <span className="description">{description}</span>
                        <span className="code">{code}</span>
                      </>
                    );
                  })}
                </span>
              ) : null}
              <br />

              <div className="product-name-div">
                <a
                  href={url}
                  target="_BLANK"
                  onClick={trackBuyNowClick}
                  style={{ color: "black" }}
                >
                  {name}
                </a>
              </div>
              <div className="website-logo">
                <img src={webLogo} alt={website} />
              </div>
            </Col>
            {/* <Col className="product-price"></Col> */}
            <Col className="more-info">
              <div className="price">
                {currency}
                {price}
              </div>
              <div className="buyNow">
                <a
                  href={url}
                  // target="_BLANK"
                  onClick={trackBuyNowClick}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="buy-button"
                  >
                    Buy Now
                  </Button>
                </a>
              </div>
              <Row>
                {showAddRemoveFavButton ? (
                  <div className="favourite-add-remove">
                    <ButtonFavouriteProduct
                      website={website}
                      productId={productId}
                    />
                  </div>
                ) : null}

                {!productPageUrl || showPriceHistoryLink === false ? null : (
                  <div className="link-price-history">
                    <Link
                      target={openLinkInNewTab ? "_blank" : ""}
                      to={productPageUrl}
                    >
                      Price History
                    </Link>
                  </div>
                )}
              </Row>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  // productInfo: state.product.product.data || {},
  // menu: state.generic.menu.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  buyNowClickRequest: (data) => dispatch(actions.buyNowClickRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductWebSearch);
