import update from "immutability-helper";
import constants from "../constants";
import * as actions from "../actions";

// this will run first time to decide either to open sidebar or not
let windowWidth = window.innerWidth;
let sideBar = false;
if (windowWidth <= 768) {
  sideBar = true;
}

// this reducer consist of information which will not change as these are substitutes of constants to make it dynamically

const initialState = {
  progressBar: {
    data: false,
  },
  sideBar: {
    data: sideBar,
  },
  menu: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  websites: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // start - progressbar
    case constants.SHOW_PROGRESS_BAR:
      return update(state, {
        progressBar: {
          data: { $set: action.payload },
        },
      });
    // end - progressbar

    // TODO - if this sidebar is not used, need to delete this code
    // start - sidebar
    case constants.SHOW_SIDEBAR:
      return update(state, {
        sideBar: {
          data: { $set: action.payload },
        },
      });
    // end - sidebar

    //start - get websites
    case actions.getWebsites.REQUEST:
      return update(state, {
        websites: {
          isLoading: { $set: true },
          isSuccess: { $set: false },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    case actions.getWebsites.SUCCESS:
      // at this time need to update websearch.websitesToProcess to use already websites in localstorage for a user
      return update(state, {
        websites: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: action?.data?.websites || [] },
        },
      });
    case actions.getWebsites.FAILURE:
      return update(state, {
        websites: {
          isLoading: { $set: false },
          isSuccess: { $set: false },
          isError: { $set: true },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    //end - get websites

    //start - get menu
    case actions.getMenu.REQUEST:
      return update(state, {
        menu: {
          isLoading: { $set: true },
          isSuccess: { $set: false },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: {} },
        },
      });
    case actions.getMenu.SUCCESS:
      return update(state, {
        menu: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: { ...action.data } },
        },
      });
    case actions.getMenu.FAILURE:
      return update(state, {
        menu: {
          isLoading: { $set: false },
          isSuccess: { $set: false },
          isError: { $set: true },
          message: { $set: "" },
          data: { $set: {} },
        },
      });
    //end - get menu

    default:
      return state;
  }
};
