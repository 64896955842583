import update from "immutability-helper";
import _ from "lodash";

import * as actions from "../actions";

const initialState = {
  products: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  },
  topRecentProducts: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  },
  topPriceDropProducts: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  },
  topPopularProducts: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    //start - top price drops
    case actions.getTopPriceDropProducts.REQUEST:
      return update(state, {
        topPriceDropProducts: {
          isLoading: { $set: true },
          isSuccess: { $set: false },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    case actions.getTopPriceDropProducts.SUCCESS:
      return update(state, {
        topPriceDropProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: action?.data?.products || [] },
        },
      });
    case actions.getTopPriceDropProducts.FAILURE:
      return update(state, {
        topPriceDropProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: false },
          isError: { $set: true },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    //end - top price drops

    //start - top popular drops
    case actions.getTopPopularProducts.REQUEST:
      return update(state, {
        topPopularProducts: {
          isLoading: { $set: true },
          isSuccess: { $set: false },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    case actions.getTopPopularProducts.SUCCESS:
      return update(state, {
        topPopularProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: action?.data?.products || [] },
        },
      });
    case actions.getTopPopularProducts.FAILURE:
      return update(state, {
        topPopularProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: false },
          isError: { $set: true },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    //end - top popular drops

    //start - top recent drops
    case actions.getTopRecentProducts.REQUEST:
      return update(state, {
        topRecentProducts: {
          isLoading: { $set: true },
          isSuccess: { $set: false },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    case actions.getTopRecentProducts.SUCCESS:
      return update(state, {
        topRecentProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: action?.data?.products || [] },
        },
      });
    case actions.getTopRecentProducts.FAILURE:
      return update(state, {
        topRecentProducts: {
          isLoading: { $set: false },
          isSuccess: { $set: false },
          isError: { $set: true },
          message: { $set: "" },
          data: { $set: [] },
        },
      });
    //end - top recent drops

    //final to use
    case actions.getCatalogProducts.SUCCESS:
      let newDataC = action?.data;
      let existingProductsC = state?.products?.data?.products || [];
      let newProductsC = newDataC.products || [];
      let mergedProductsC = _.unionBy(existingProductsC, newProductsC);
      newDataC["products"] = mergedProductsC;
      return update(state, {
        products: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: { ...newDataC } },
        },
      });
    case actions.getCatalogProducts.REQUEST:
      return update(state, {
        products: {
          isLoading: { $set: false },
          isSuccess: { $set: true },
          isError: { $set: false },
          message: { $set: "" },
          data: { $set: { ...{} } },
        },
      });
    default:
      return state;
  }
};
